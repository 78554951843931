let SnapSlider = function (list, options) {
	this.init(list, options);
};
// Якщо включений loop і викл. autoscroll
// То є проблема, слайдер скролить від початку до кінця безкінечно
SnapSlider.prototype = {
	init: function (list, options) {
		this.list = list;
		this.slides = {
			all: this.list.children,
			first: this.list.children[0],
			last: this.list.children[this.list.children.length - 1],
		};
		this.options = Object.assign(
			{},
			{
				observer: true,
				prev: null,
				next: null,
				autoscroll: false,
				infinity: false,
				pauseOnHover: false,
				overflow: false,
				autoscrollDelay: 5000,
			},
			options
		);

		if (this.options.observer) {
			this.setObserver();
		}

		if (this.options.autoscroll) {
			this.startAutoscroll();
		}

		if (this.options.pauseOnHover) {
			this.list.addEventListener("mouseenter", this.stopAutoscroll.bind(this));
			this.list.addEventListener("mouseleave", this.startAutoscroll.bind(this));
		}

		this.list.classList.add("overflow-x-auto", "scrollbar-hide", "snap-x");

		if (this.options.prev) {
			this.options.prev.addEventListener("click", () => {
				this.goToPrev();
				// this.stopAutoscroll();
				// setTimeout(() => this.startAutoscroll(), this.options.autoscrollDelay);
			});
		}
		if (this.options.next) {
			this.options.next.addEventListener("click", () => {
				this.goToNext();
				// this.stopAutoscroll();
				// setTimeout(() => this.startAutoscroll(), this.options.autoscrollDelay);
			});
		}
	},

	startAutoscroll: function () {
		this.autoscrollTimer = setInterval(() => {
			this.goToNext();
		}, this.options.autoscrollDelay);
	},
	stopAutoscroll: function () {
		clearInterval(this.autoscrollTimer);
	},
	goToPrev: function () {
		this.goTo("prev");
	},
	goToNext: function () {
		this.goTo("next");
	},
	goTo: function (slide) {
		let coeffSlide;
		const showSlide = this.list.querySelector(".show");
		if (!showSlide) {
			// Handle error (e.g. no slide with class 'show' found)
			return;
		}
		const width = window.getComputedStyle(showSlide).width,
			columnGap = window.getComputedStyle(this.list).columnGap,
			step = parseFloat(width) + parseFloat(columnGap);

		const index = [...this.slides.all].findIndex((el) =>
			el.classList.contains("show")
		);

		if (slide == "prev") {
			coeffSlide = index - 1;
		} else if (slide == "next") {
			coeffSlide = index + 1;
		} else if (typeof slide == "number") {
			coeffSlide = slide;
		}

		const leftPoint = coeffSlide * step;

		this.list.scrollTo({
			top: 0,
			left: leftPoint,
			behavior: "smooth",
		});
	},
	setObserver: function () {
		this.observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("show");
						entry.target.classList.add("fadeIn");
						entry.target.classList.add("animated");
						entry.target.classList.remove("fadeOut");
						if (this.options.overflow) {
							entry.target.classList.remove("opacity-0");
							entry.target.classList.remove("pointer-events-none");
						}
					} else {
						entry.target.classList.remove("show");
						entry.target.classList.remove("fadeIn");
						entry.target.classList.add("fadeOut");
						if (this.options.overflow) {
							entry.target.classList.add("opacity-0");
							entry.target.classList.add("pointer-events-none");
						}
					}
				});

				this.watchForChanges(entries);
			},
			{
				root: this.list,
				threshold: 0.5,
			}
		);
		[...this.slides.all].forEach((el) => this.observer.observe(el));
	},
	watchForChanges: function (entries) {
		if (entries.length > 1) {
			this.scrollEndListener(entries[entries.length - 1]);
			this.scrollStartListener(entries[0]);
		} else {
			this.scrollEndListener(entries[0]);
			this.scrollStartListener(entries[0]);
		}
	},
	isLastSlide: function (slide) {
		return (
			slide.isIntersecting &&
			slide.target.dataset.id === this.slides.last.dataset.id
		);
	},
	isFirstSlide: function (slide) {
		return (
			slide.isIntersecting &&
			slide.target.dataset.id === this.slides.first.dataset.id
		);
	},
	scrollEndListener: function (entry) {
		const isLastSlide = this.isLastSlide(entry);
		const arrowDisabled = isLastSlide;
		if (this.options.infinity && isLastSlide) {
			setTimeout(() => {
				this.goTo(0);
			}, this.options.autoscrollDelay);
		}
		this.options.next.disabled = arrowDisabled;
	},
	scrollStartListener: function (entry) {
		const isFirstSlide = this.isFirstSlide(entry);
		let arrowDisabled = isFirstSlide;
		if (isFirstSlide && this.options.infinity && !this.options.autoscroll) {
			setTimeout(() => {
				this.goTo(this.slides.all.length - 1);
			}, this.options.autoscrollDelay);
		}
		if (this.options.infinity) {
			arrowDisabled = false;
		}
		if (isFirstSlide && this.options.infinity && this.options.autoscroll) {
			arrowDisabled = true;
		}
		this.options.prev.disabled = arrowDisabled;
	},
	destroy: function () {
		console.log("destroy");
		// Remove event listeners from prev and next buttons
		if (this.options.prev) {
			this.options.prev.removeEventListener("click", () => {
				this.goToPrev();
			});
		}
		if (this.options.next) {
			this.options.next.removeEventListener("click", () => {
				this.goToNext();
			});
		}

		// Stop intersection observer and disconnect it
		this.observer.disconnect();
		clearInterval(this.autoscrollTimer);
		// Remove classes added to the list
		// this.list.classList.remove("overflow-x-auto", "scrollbar-hide", "snap-x");

		// Remove classes added to slides
		Array.from(this.slides.all).forEach((el) => {
			el.classList.remove("show");
			el.classList.remove("animated");
			el.classList.remove("fadeOut");
			el.classList.remove("opacity-0");
			el.classList.remove("pointer-events-none");
		});
		console.log(this.slides.all);
	},
};
export default SnapSlider;
